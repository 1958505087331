export enum PhotoStatusEnum {
  // BE enum
  NotConfirmed = 0,
  ConfirmedByRecruit = 1,

    // FE linked
  notConfirmed = NotConfirmed,
  completed = ConfirmedByRecruit,
}

export enum PhotoDisplayStatusEnum {
  notConfirmed = 'not confirmed',
  completed = 'completed',
}
