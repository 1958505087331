import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store/index';
import {
  ActionTypes,
  TypeAction
} from './confirm-photo.actions';
import { PhotoDisplayStatusEnum, PhotoStatusEnum } from './constants';

const photoStatusText = {
  completed: 'Your Profile Photo is successfully completed!',
};
export interface IState {
  status: number | null;
  photo: string | null;
  userImageBase64: string | null;
}

const initialState: IState = {
  status: null,
  photo: null,
  userImageBase64: null
};

export function confirmPhotoReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.PHOTO_STORE: {
      return Object.assign({}, state, payload);
    }
    case ActionTypes.PHOTO_CHANGE: {
      return Object.assign({}, state, { photo: payload, userImageBase64: payload });
    }

    default: {
      return state;
    }
  }
}

export interface IPhotoStatus {
  status: string;
  text: string;
  displayStatus: string;
}

export const getPhotoStatus = createSelector(
  ({ confirmPhoto }: IRootState): IState => confirmPhoto,
  ({ status }: IState): IPhotoStatus => ({
    status: PhotoStatusEnum[status],
    text: photoStatusText[PhotoStatusEnum[status]],
    displayStatus: PhotoDisplayStatusEnum[PhotoStatusEnum[status]]
  })
);

export const getPhotoStatusCode = createSelector(
  ({ confirmPhoto }: IRootState): IState => confirmPhoto,
  ({ status }: IState): number | null => status
);

export const getIsProfilePhotoCompleted = createSelector(
  ({ confirmPhoto }: IRootState): IState => confirmPhoto,
  ({ status }: IState): boolean => Boolean(status === PhotoStatusEnum.completed)
);

export const getProfilePhoto = createSelector(
  ({ confirmPhoto }: IRootState): IState => confirmPhoto,
  ({ photo }: IState): string | null => photo
);

export const getProfileImageBase64 = createSelector(
  ({ confirmPhoto }: IRootState): IState => confirmPhoto,
  ({ userImageBase64 }: IState): string => userImageBase64
);
